import styled, { css } from 'styled-components';
import config from 'src/styles/style.config.json';

import { Input } from 'components/input/input.styles';
import { Select } from 'components/select/select.styles';
import { Button } from 'components/button/button.styles';

import {
  FieldsetProps,
  FieldsetStyledProps,
  GroupedInnerProps,
} from './fieldset.definition';

export const Fieldset = styled.div<Pick<FieldsetStyledProps, 'hasError'>>`
  position: relative;
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  justify-content: right;
  margin-bottom: 15px;

  ${({ hasError }) =>
    hasError &&
    css`
      ${Input},
      ${Button},
      i {
        &,
        &:focus,
        &:hover {
          color: ${config.font.color.error};
          border-color: ${config.border.color.error};
          background-color: ${config.background.error};
        }
      }

      ${Select} {
        .single-select__control {
          border-color: ${config.border.color.error};
          background-color: ${config.background.error};
        }
        .single-select__placeholder {
          color: ${config.font.color.error};
        }
        .single-select__indicator-separator {
          background-color: ${config.border.color.error};
        }
      }

      svg path {
        fill: ${config.font.color.error};
      }
    `};

  button {
    max-height: 48px;
    margin: 0 0 0 1rem;

    @media (min-width: ${config.breakpoints.small_tablet}) {
      max-height: inherit;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

export const Label = styled.label<FieldsetProps>`
  display: none;
  width: 6.4rem;
  font-family: ${config.font.family.regular};
  font-weight: ${config.font.weight.regular};
  font-size: 0.9rem;
  text-transform: capitalize;
  align-items: center;
  margin-right: 1rem;
  justify-content: flex-end;
  color: ${({ hasError }) => hasError && config.font.color.error};

  @media (min-width: ${config.breakpoints.mobile}) {
    display: flex;
  }

  @media (min-width: ${config.breakpoints.small_tablet}) {
    width: 12rem;
    font-size: 1.2rem;
    margin-right: 2rem;
  }
`;

export const Grouped = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex: 1;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 15px;

  ${Label} {
    display: none;
    align-items: flex-start;
    padding: 35px 0;
    line-height: 0;

    @media (min-width: ${config.breakpoints.mobile}) {
      display: flex;
    }
  }

  ${Input} {
    margin-bottom: -1px;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: ${config.border.radius.desktop / 2}rem;
      border-top-right-radius: ${config.border.radius.desktop / 2}rem;
    }
  }
`;

export const GroupedInner = styled.div<GroupedInnerProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;

  & > ${Input} {
    width: ${({ disabled }) => (disabled ? '100%' : `calc(100% - 86px)`)};
  }

  & > &:last-child {
    flex-direction: row;

    ${Input} {
      border-top-left-radius: ${({ radiusOverride }) =>
        radiusOverride ? `${config.border.radius.desktop / 2} rem` : 0};
      border-top-right-radius: ${({ radiusOverride }) =>
        radiusOverride ? `${config.border.radius.desktop / 2} rem` : 0};
      border-bottom-left-radius: ${config.border.radius.desktop / 2}rem;
      border-bottom-right-radius: ${config.border.radius.desktop / 2}rem;
    }

    ${Button} {
      margin-left: 16px;
    }
  }

  & > &:only-child {
    ${Input} {
      border-radius: ${config.border.radius.desktop / 2}rem;
    }
  }
`;
