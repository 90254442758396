import React, { FC, useEffect, useState } from 'react';

import { FieldConfiguration } from 'lib/types/entities/field.definition';

import { Button } from 'components/button/button.component';
import { Input } from 'components/input/input.component';

import * as S from 'components/fieldset/fieldset.styles';
import { InvoicesFieldsetProps, FieldRecord } from './invoices.definition';

export const InvoicesFieldset: FC<InvoicesFieldsetProps> = ({
  className,
  value: invoices,
  onChange,
  disabled = false,
  hidden,
}) => {
  const [fields, setFields] = useState<FieldConfiguration<string>[]>(
    invoices?.map((invoice, key) => ({
      name: invoice,
      type: 'string',
      value: invoices[key],
    })) || []
  );
  const emptyField: FieldRecord = {
    name: 'new-invoice',
    type: 'string',
    value: '',
  };
  const [newField, setNewField] = useState(emptyField);

  const newInvoice = () => {
    const _fields = [...fields];
    _fields.push(newField);
    setFields(_fields);
    setNewField(emptyField);

    const input = document.getElementById(
      'input-invoice-new'
    ) as HTMLInputElement | null;

    if (input) {
      input.value = '';
    }
  };

  const changeHandler = ({ name, value }: Partial<FieldRecord>) => {
    if (name && value) {
      const changed = fields.map((field) => field?.value && field.value);

      if (name === 'invoice-new') {
        setNewField({ name, value, type: 'string' });

        const found = changed.find((invoice) => invoice === value);
        if (!found) {
          changed.push(value);
        }
      }

      onChange({ value: changed as Array<string> });
    }
  };

  if (hidden) return null;

  return (
    <>
      {fields.length > 0 && (
        <S.GroupedInner className={className} disabled={disabled}>
          {fields.map((field, index) => (
            <Input
              key={`invoice-${index}`}
              name={`invoice-${index}`}
              field={field}
              value={field.value as string}
              onChange={changeHandler}
              disabled={disabled}
              autoComplete="off"
            />
          ))}
        </S.GroupedInner>
      )}
      <S.GroupedInner disabled={disabled}>
        <Input
          name="invoice-new"
          value={newField.value || ''}
          field={newField}
          onChange={changeHandler}
          autoComplete="off"
          disabled={disabled}
        />
        {!disabled && (
          <Button
            theme="theme"
            name="new-invoice"
            icon="add"
            shape="square"
            onClick={newInvoice}
            type="button"
          />
        )}
      </S.GroupedInner>
    </>
  );
};

export const InvoicesFieldsetDefinition = {
  type: 'invoices',
  component: InvoicesFieldset,
};
