import config from 'src/styles/style.config.json';
import styled from 'styled-components';

import ReactSelect from 'react-select/async';

export const Select = styled(ReactSelect)`
  flex: 1;

  .single-select {
    &__control {
      border-radius: ${config.border.radius.desktop / 2}rem;
      box-shadow: none;
      outline: 0;
      cursor: pointer;

      &,
      &:hover {
        border: 1px solid ${config.border.color.primary};
      }

      &--is-focused {
        &,
        &:hover {
          border: 1px solid ${config.border.color.accent};
        }

        .single-select {
          &__indicator {
            transform: rotate(180deg);
            color: ${config.border.color.accent};
          }
          &__indicator-separator {
            background-color: ${config.border.color.accent};
          }
        }
      }
    }

    &__value-container {
      text-align: left;
      direction: ltr;
      padding: 1rem;
      height: 70px;
      font-family: ${config.font.family.regular};
      font-weight: ${config.font.weight.regular};
      font-size: 0.9rem; // TODO: Create reusable font size style config

      & > div {
        color: ${config.font.color.primary};
      }

      @media (min-width: ${config.breakpoints.small_tablet}) {
        padding: 1.4rem 2rem;
        font-size: 1.1rem;
      }
    }

    &__menu {
      direction: ltr;

      .single-select__option {
        padding: 1rem;
        cursor: pointer;

        @media (min-width: ${config.breakpoints.small_tablet}) {
          padding: 1.4rem 2rem;
        }

        &--is-selected {
          background-color: ${config.theme.primary};
        }
      }
    }

    &__indicator {
      &,
      &:hover {
        color: ${config.border.color.primary};
      }
    }

    &__dropdown-indicator {
      padding: 24px;
    }
  }
`;
