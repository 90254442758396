import React, { FC, useState } from 'react';

import { strToBoolean } from 'lib/utilities/string.util';

import { Button } from 'components/button/button.component';

import { FieldConfiguration } from 'lib/types/entities/field.definition';

import * as S from './completed.styles';

export type CompletedFieldProps = {
  field: FieldConfiguration<string>;
  value?: number;
  onChange: (record: { value?: string; validationErrors?: string[] }) => void;
  disabled?: boolean;
};

export const CompletedField: FC<CompletedFieldProps> = ({
  field,
  onChange,
  disabled = false,
}) => {
  const value =
    (field.value && !Array.isArray(field.value) && strToBoolean(field.value)) ||
    false;
  const [completedToggle, setCompletedToggle] = useState(value);

  const changeHandler = () => {
    const value = !completedToggle;
    setCompletedToggle(value);
    onChange({
      value: value.toString(),
    });
  };

  return (
    <S.Completed>
      <Button
        type="button"
        name="completed"
        theme={completedToggle ? 'success' : 'default'}
        onClick={changeHandler}
        disabled={disabled}
      >
        {completedToggle ? 'Completed' : 'Incomplete'}
      </Button>
    </S.Completed>
  );
};

export const CompletedFieldDefinition = {
  type: 'completed',
  component: CompletedField,
};
