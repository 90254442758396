import React, { FC } from 'react';

import { Input } from 'components/input/input.component';
import { FieldConfiguration } from 'lib/types/entities/field.definition';

export type JobNumberFieldsetProps = {
  field: FieldConfiguration<string>;
  value: number;
  onChange: (record: { value?: string; validationErrors?: string[] }) => void;
  disabled?: boolean;
};

export const JobNumberFieldset: FC<JobNumberFieldsetProps> = ({
  field,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <Input
      name="number"
      type="number"
      field={field}
      value={value?.toString()}
      onChange={onChange}
      disabled={disabled}
      required
    />
  );
};

export const JobNumberFieldDefinition = {
  type: 'jobnumber',
  component: JobNumberFieldset,
};
