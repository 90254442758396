import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';

import { EntityTypes } from 'lib/services/entity/entity.definition';

import { OptionProps } from './select.definition';

export const doSearch = async (type: EntityTypes, searchTerm: string) => {
  let results: OptionProps[] = [];

  try {
    // TODO: Refactor to EntityService
    const supabase = createBrowserSupabaseClient();
    const { data } = await supabase
      .from(`${type}s`)
      .select()
      .ilike('name', `%${searchTerm}%`);

    if (data) {
      results = data.map((entity) => ({
        label: entity.name,
        value: entity._id,
      }));
    }
  } catch (e) {
    console.log(e);
  }

  return results;
};
