import styled from 'styled-components';
import config from 'src/styles/style.config.json';

const inputStyles = `
  flex: 1;
  height: 70px;
  padding: 1rem;
  font-family: ${config.font.family.regular};
  font-weight: ${config.font.weight.regular};
  font-size: 0.9rem; // TODO: Create reusable font size style config
  color: ${config.font.color.primary};
  background-color: #ffffff;
  border: 1px solid ${config.border.color.primary};
  border-radius: ${config.border.radius.desktop / 2}rem;
  outline: 0;

  @media (min-width: ${config.breakpoints.small_tablet}) {
    padding: 1.4rem 2rem;
    font-size: 1.1rem;
  }
`;

export const Input = styled.input`
  ${inputStyles}

  &:focus {
    border-color: ${config.border.color.accent};
    outline: 0;
    z-index: 1;
  }

  &:disabled {
    background-color: ${config.background.disabled};
  }
`;

export const InputDisabled = styled.div`
  ${inputStyles}
  display: flex;
  align-items: center;

  .placeholder {
    color: ${config.font.color.disabled};
  }
`;
