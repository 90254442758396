import styled from 'styled-components';
import config from 'src/styles/style.config.json';

import { Label } from 'components/fieldset/fieldset.styles';
import { Input } from 'components/input/input.styles';

export const Grouped = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;

  ${Label} {
    display: flex;
    align-items: flex-start;
    padding: 35px 0;
    line-height: 0;
  }

  ${Input} {
    margin-bottom: -1px;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: ${config.border.radius.desktop / 2}rem;
      border-top-right-radius: ${config.border.radius.desktop / 2}rem;
    }

    &:last-child {
      border-bottom-left-radius: ${config.border.radius.desktop / 2}rem;
      border-bottom-right-radius: ${config.border.radius.desktop / 2}rem;
    }
  }
`;
