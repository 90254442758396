import React, { FC } from 'react';

import { validate } from 'lib/errors/validate.util';

import { InputProps } from './input.definition';

import * as S from './input.styles';

export const Input: FC<InputProps> = ({
  className,
  name,
  field,
  value,
  type = 'text',
  autoComplete,
  placeholder,
  required,
  hidden,
  disabled,
  onChange,
}: InputProps) => {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let changedValue: string = event.target.value;
    let errors;

    if (field.parse) {
      changedValue = field.parse(changedValue, field.type, field);
    }

    if (field.format) {
      changedValue = field.format(changedValue, field.type, field);
    }

    if (field.isRequired) {
      errors = validate(changedValue, field);
    }

    onChange({
      value: changedValue,
      validationErrors: errors,
      name: event.target.name,
    });
  };

  if (disabled) {
    return (
      <S.InputDisabled>
        {value || <span className="placeholder">{placeholder}</span>}
      </S.InputDisabled>
    );
  }

  return (
    <S.Input
      id={`input-${name}`}
      name={name}
      className={className}
      type={type}
      defaultValue={value}
      placeholder={placeholder}
      onChange={changeHandler}
      hidden={hidden}
      disabled={disabled}
      required={required}
      autoComplete={autoComplete}
    />
  );
};

export const InputFieldDefinition = {
  type: ['text', 'number', 'password'],
  component: Input,
};
