import React, { FC, useState } from 'react';

import { Input } from 'components/input/input.component';
import { AddressFieldProps } from './address.definition';

import * as S from './address.styles';

export const AddressField: FC<AddressFieldProps> = ({
  className,
  value: address,
  onChange,
  disabled = false,
}: AddressFieldProps) => {
  const [_address, setAddress] = useState(
    typeof address === 'object' ? { ...address } : { ...JSON.parse(address) }
  );

  const lines = Object.keys(_address).map((line) => ({
    name: line,
    type: 'string',
    value: _address[line], // REVIEW: Object key accessor not respected by type
  }));

  const changeHandler = ({
    value,
    name,
  }: {
    value?: string;
    name?: string;
  }) => {
    if (name && value) {
      const changed = { ..._address };
      changed[name] = value;
      setAddress(changed);

      onChange({
        value: _address as unknown as string,
      });
    }
  };

  return (
    <>
      <S.Grouped className={className}>
        {lines.map((line) => (
          <Input
            key={line.name}
            name={line.name}
            field={line}
            value={line.value}
            onChange={changeHandler}
            disabled={disabled}
            autoComplete="off"
          />
        ))}
      </S.Grouped>
      <input name="address" value={JSON.stringify(_address)} hidden />
    </>
  );
};

export const AddressFieldDefinition = {
  type: 'address',
  component: AddressField,
};
