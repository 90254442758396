import React from 'react';

import { InputFieldDefinition } from 'components/input/input.component';
import { SelectFieldDefinition } from 'components/select/select.component';
import { AddressFieldDefinition } from 'src/fields/address/address.field';

// REVIEW Partial imports
import { CompletedFieldDefinition } from 'src/fields/completed/completed.field';
import { InvoicesFieldsetDefinition } from 'src/fields/invoices/invoices.field';
import { JobNumberFieldDefinition } from 'src/fields/job-number/job-number.field';

type FieldDefinition = { type: string | string[]; component: React.FC<any> };
type FieldsGlob = Record<string, FieldDefinition>;

const fields: FieldsGlob = {
  text: InputFieldDefinition,
  number: InputFieldDefinition,
  password: InputFieldDefinition,
  select: SelectFieldDefinition,
  jobnumber: JobNumberFieldDefinition,
  completed: CompletedFieldDefinition,
  invoices: InvoicesFieldsetDefinition,
  address: AddressFieldDefinition,
};

export const FIELD_MAP: FieldDefinition[] = Object.keys(fields).map(
  (x) => fields[x]
);

export const mapFieldIdentifierToFieldComponent = (
  identifier: string
): React.FC<unknown> | undefined => {
  if (FIELD_MAP !== undefined) {
    const field:
      | { type: string | string[]; component: React.FC<unknown> }
      | undefined = FIELD_MAP.find((k) => {
      return k.type.includes(identifier);
    });

    if (field) {
      return field.component;
    }
  }

  return undefined;
};

export const hasError = (
  name: string,
  errors: { [index: string]: boolean }
) => {
  if (name in errors) {
    return !!errors[name];
  }

  return false;
};
