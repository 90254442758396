import React, { FC } from 'react';

import { validate } from 'lib/errors/validate.util';

import { Input } from 'components/input/input.component';

import { EntityTypes } from 'lib/services/entity/entity.definition';
import { OptionProps, SelectProps } from './select.definition';

import { doSearch } from './search.util';

import * as S from './select.styles';

export const Select: FC<SelectProps> = ({
  className,
  name,
  options,
  field,
  value,
  placeholder,
  isSearchable = true,
  isClearable = false,
  onChange,
  required,
  disabled = false,
}: SelectProps) => {
  const changeHandler = (changedValue?: string) => {
    if (changedValue) {
      let errors;

      if (field.parse)
        changedValue = field.parse(changedValue, field.type, field);

      if (field.format)
        changedValue = field.format(changedValue, field.type, field);

      if (field.validate) errors = validate(changedValue, field);

      onChange({ value: changedValue, validationErrors: errors });
    }
  };

  const searchHandler = async (
    inputValue: string,
    callback: (options: OptionProps[]) => void
  ) => {
    const searchTerm = inputValue.replace(/\W\s/g, '').toLowerCase();
    callback(await doSearch(field.name as EntityTypes, searchTerm));

    return searchTerm;
  };

  if (disabled) {
    return (
      <Input
        name="invoice-new"
        value={value?.label as string}
        field={field}
        autoComplete="off"
        disabled={disabled}
        // eslint-disable-next-line no-console
        onChange={(record) => console.log(record.value)}
      />
    );
  }

  // REVIEW: Value state not updated when existing value changed
  // NOTE: Only works for new values

  return (
    <>
      <S.Select
        className={className}
        classNamePrefix="single-select"
        name={name}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={value}
        defaultOptions={options}
        isMulti={false}
        isRtl={false}
        placeholder={placeholder}
        onChange={changeHandler}
        loadOptions={searchHandler}
        required={required}
      />
    </>
  );
};

export const SelectFieldDefinition = {
  type: 'select',
  component: Select,
};
