import React from 'react';

import { FieldsetProps } from './fieldset.definition';

import * as S from './fieldset.styles';

export function Fieldset({
  className,
  hidden,
  isGrouped,
  label,
  hasError,
  children,
}: FieldsetProps): JSX.Element {
  if (isGrouped) {
    return (
      <S.Grouped className={className} hidden={hidden}>
        {label && <S.Label>{label}</S.Label>}
        <S.GroupedInner className={className}>{children}</S.GroupedInner>
      </S.Grouped>
    );
  }

  return (
    <S.Fieldset className={className} hasError={hasError} hidden={hidden}>
      {label && <S.Label>{label}</S.Label>}
      {children}
    </S.Fieldset>
  );
}
