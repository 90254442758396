import { strFirstToUpper } from 'lib/utilities/string.util';
import {
  EntityConfiguration,
  FieldConfiguration,
} from 'lib/types/entities/field.definition';

export function validate<T>(
  value: T,
  field: FieldConfiguration<T>
): string[] | undefined {
  const validationErrors = [];

  if (!value && field?.isRequired) {
    validationErrors.push(
      `${field?.label || strFirstToUpper(field.name)} is required.`
    );
  }

  return validationErrors.length > 0 ? validationErrors : undefined;
}

export const validateEntity = (entityConfig: EntityConfiguration) => {
  const errors: Record<string, boolean> = {};
  entityConfig?.fields.forEach((field) => {
    errors[field.name] = !!validate(field.value, field);
  });

  return errors;
};
