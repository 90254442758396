import styled from 'styled-components';
import config from 'src/styles/style.config.json';

import { Button } from 'components/button/button.styles';

export const Completed = styled.div`
  ${Button} {
    height: 70px;
    max-height: 70px;

    span {
      font-size: 1rem;

      @media (min-width: ${config.breakpoints.small_tablet}) {
        font-size: 1.2rem;
      }
    }
  }
`;
